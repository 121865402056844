<template>
    <article class="stream-article">
        <header>
            <h1 v-if="data.fields.title">{{data.fields.title}}</h1>
        </header>
        <p v-if="data.fields.ingress">{{ data.fields.ingress }}</p>
        <main class="content" v-if="content" v-html="content" />
        <footer class="tellmemore" @click="tellMeMore" v-if="tellmore">
            <input type="checkbox" :checked="tellMore"> Detta vill jag veta mer om!
        </footer>
    </article>
</template>

<script>

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default {
    name: 'informationarticle',
    props: ['data', 'tellmore'],
    data(){
        return {
            content: '',
            tellMore: false
        }
    },
    methods: {
        tellMeMore(){
        
            this.tellMore = !this.tellMore;
            this.$store.commit('tellMeMore', this.data.fields.title)

        }
    },
    async beforeMount(){
        if(this.data.fields.content){
            this.content = documentToHtmlString(this.data.fields.content);
        }
    }
}
</script>

<style lang="scss">
@import '../../scss/variables';

.stream-article {

    h1 {
        margin: 1.5rem 0 .5rem 0;
    }

    h2 {
        margin: 1.5rem 0 .5rem 0;
    }

    p {
        .btn {
            display: inline-flex;
            width: 100%;
            padding: 1rem;
            border: 2px solid $darkgrey;
            border-radius: $radius;
            @extend %center;
            text-decoration: none;
            color: $darkgrey;
            font-weight: 700;
        }
    }

    ul {
        list-style: square !important;

        li {
            padding: 0 .75rem;
            margin: 0 0 .75rem 0;
            border-left: .125rem solid rgba($color: #000000, $alpha: .2);

            p {
                margin: 0;
                padding: 0;
                font-style: italic;
                font-size: .8rem;
            }
        }
    }

    .tellmemore {
        background: rgba($color: $darkgrey, $alpha: .05);
        display: flex;
        align-items: center;
        border-radius: $radius;
        height: auto !important;
        font-size: .8rem;
        font-style: italic;

        [type="checkbox"] {
            margin: .5rem;
            appearance: none;
            border: 1px solid rgba($color: #000000, $alpha: .8);
            border-radius: $radius;
            width: 1rem;
            height: 1rem;

            &:focus {
                outline: none;
            }

            &:checked {
                background: url('../../assets/icon-close.png');
                background-size: contain;
            }
        }
    }
}
</style>
