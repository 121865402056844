<template>
  <main id="info">
    <section class="content">
    <img class="prev" src="../assets/chevron-left.svg" alt="next" @click="$emit('move', { transitionType: 'prev', to: '/test' })">
    <img class="next" src="../assets/chevron-right.svg" alt="next" @click="$emit('move', { transitionType: 'next', to: '/inspiration' })">
      <section class="intro" v-hammer:swipe.left.right="goTo">
        <pager :activePage="4" />
        <h1 class="main">Information</h1>
      </section>
      <section class="floating">
        <article class="menu">
          <a href="#" @click.prevent="scrollToAnchorPoint('how')">Hur gymnasievalet går till</a>
          <a href="#" @click.prevent="scrollToAnchorPoint('getin')">Vem kan söka till gymnasiet</a>
          <a href="#" @click.prevent="scrollToAnchorPoint('dates')">Viktiga datum</a>
          <a href="#" @click.prevent="scrollToAnchorPoint('programs')">Gymnasieprogrammen</a>
          <a href="#" @click.prevent="scrollToAnchorPoint('behorighet')">Behörighet</a>
          <a href="#" @click.prevent="scrollToAnchorPoint('merit')">Meritvärde</a>
          <a href="#" @click.prevent="scrollToAnchorPoint('myths')">Myter om gymnasieprogrammen</a>
          <a href="#" @click.prevent="scrollToAnchorPoint('links')">Användbara länkar</a>
          <a href="#" @click.prevent="scrollToAnchorPoint('antagning')">Antagningsstatistik 2023</a>
        </article>

        <!-- HUR GÅR DET TILL --> 
        <flowarticle ref="how" :data="articles.filter(article => article.sys.id == '2sPMsLglqHgkJApqpnNqPD')[0]" :knowmore="true" />

        <!-- VEM KAN SÖKA -->
        <flowarticle ref="getin" :data="articles.filter(article => article.sys.id == '6U7mQqCfJL59Etb48wX5fq')[0]" :knowmore="true" />

        <!-- VIKTIGA DATUM -->
        <flowarticle class="important" ref="dates" :data="articles.filter(article => article.sys.id == '6OLkYSbDC4IrvDn2d7l8t0')[0]" :knowmore="false" :tellmore="false" />

        <!-- PROGRAMMEN -->         
        <flowarticle ref="programs" :data="articles.filter(article => article.sys.id == '2peZgWhUNAdrWiSxFURHcD')[0]" :knowmore="false" :tellmore="false" />
        <programarticle v-for="program of programs" :key="program.code" :data="program" />

        <!-- BEHÖRIGHETER -->
        <flowarticle ref="behorighet" :data="articles.filter(article => article.sys.id == '4X8LIDogew3PR27eiFJodA')[0]" :knowmore="true" />

        <!-- MERITVÄRDE -->
        <flowarticle ref="merit" :data="articles.filter(article => article.sys.id == '7K5jXAPWNdpL4196XNXSgj')[0]" :knowmore="true" />

        <!-- MYTER -->
        <flowarticle ref="myths" :data="articles.filter(article => article.sys.id == '52qPgiUF4kQ61Lqd4KVVqK')[0]" :knowmore="false" />

        <!-- LINKS -->
        <flowarticle ref="links" :data="articles.filter(article => article.sys.id == '2JEpcg8hNyVyO4gWyl2jmD')[0]" :knowmore="false" />

        <!-- STATISTIK COMPONENT -->
        <Stats ref="antagning" />
        
      </section>
    </section>
  </main>
</template>

<script>

import pager from '@/components/Pager';
import Stats from '@/components/Stats';
import flowarticle from '@/components/articles/InformationArticle';
import programarticle from '@/components/articles/ProgramArticle';

export default {
  name: 'information',
  components: {
    pager,
    programarticle,
    flowarticle,
    Stats
  },
  methods: {
    scrollToAnchorPoint(refName) {
      let el = this.$refs[refName].$el;
      el.scrollIntoView({ behavior: 'smooth'})
    },
    goTo(e){
      if(e.direction === 2) {
        this.$emit('move', { transitionType: 'next', to: '/inspiration' });
      } else {
        this.$emit('move', { transitionType: 'prev', to: '/test' });
      }
    }
  },
  computed: {
    articles(){
      return this.$store.getters.information;
    },
    programs(){
      return this.$store.getters.programs;
    }
  }
}
</script>
<style lang="scss">
@import '../scss/variables';

#info {

  .intro {
    background: $blue !important;
    position: relative;

    .pager {
      position: absolute;
      top: 0;
    }
  }

  .floating {
    
    .menu {
      background: $darkgrey;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;

      a {
        color: white;
      }
    }
    
    .important {
      background: rgba($color: $red, $alpha: .1);
      padding: .25rem .75rem;
      margin-top: 1rem;
    }

    .totop {
      position: fixed;
      right: 1rem;
      bottom: 1rem;
      width: 2rem;
      padding: .25rem;
      border: .125rem solid black;
      border-radius: $radius;
      opacity: .6;
    }
  }
}
</style>

