<template>
  <section id="stats">
    <flowarticle :data="intro" :knowmore="false" />
  <!--  <h1>Antagningsstatistik {{year}}</h1>
    <p><b>Här hittar du de utbildningar som de olika gymnasieskolorna i Göteborgsregionen erbjöd läsåret {{year}}.</b></p>
    <p>Du ser också den lägsta antagningspoängen och medelpoängen för de som blev antagna. Observera att poängen avser läsåret {{year}} och kan komma att ändras, både uppåt och nedåt vid årets antagning. Du kan söka och filtrera på samtliga rubriker för att på så sätt hitta de program och poäng du efterfrågar. Du har även möjlighet att ange din egen meritpoäng för att på så sätt se vilka utbildningar du haft möjlighet att bli antagen på.</p>
    -->
    <label for="grade">Meritvärde</label>
    <input type="text" name="grade" placeholder="ditt meritvärde ( valfritt )" v-model="meritValue">
    <label for="programs">Program</label>
    <select v-if="formData" name="programs" v-model="program">
        <option v-for="program in formData.programNames" :value="program.code" :key="program.code">{{program.name}}</option>
    </select>
    <label for="municipalities">Kommun</label>
    <select v-if="formData" name="municipalities" v-model="municipality">
        <option v-for="(item, index) in formData.municipalities" :value="item" :key="index">{{item}}</option>
    </select>
    <label for="school">Skola</label>
    <select v-if="formData" name="school" v-model="school">
        <option v-for="(school, index) in formData.schools" :value="school" :key="index">{{school}}</option>
    </select>
    <a href="#" class="btn" @click.prevent="query">Sök</a>
  </section>
</template>

<script>
import flowarticle from '@/components/articles/InformationArticle';

export default {
    name: 'Stats',
    components: {
        flowarticle
    },
    data(){
        return {
            school: 'all',
            municipality: 'all',
            program: 'all',
            meritValue: '',
            year: '2023'
        }
    },
    computed: {
        formData(){
            return this.$store.state.formData;
        },
        intro(){
        return this.$store.getters.information.filter(article => article.sys.id == '1TvnWgcKT09ot9P0XQNryV')[0]
        }
    },
    methods: {
        query(){
            let query = { school: this.school, municipality: this.municipality, program: this.program, meritValue: this.meritValue };
            this.$store.dispatch('sendQuery', query)
            this.$router.push('/information/results')
        }
    }
}
</script>

<style lang="scss">
@import '../scss/variables';

#stats {
    display: flex;
    flex-direction: column;

    label {
        font-weight: 800;
        font-size: .8rem;
        margin: 1rem 0 .25rem 0;
    }

    input, select {
        padding: .5rem;
        font-size: 1.2rem;
        border-radius: $radius;
    }

    .btn {
        margin-top: 2rem;
    }
}

</style>