<template>
    <aside class="pager">
        <ul>
            <li v-for="(page, index) of pages" :key="index" :class="{ active : (activePage-1) == index}" @click="goTo(page)"></li>
        </ul>
    </aside>
</template>

<script>
export default {
    name: 'Pager',
    props: ['activePage'],
    computed: {
        pages(){
            return this.$store.state.pages;
        }
    },
    methods: {
        goTo(page){

            switch(page) {
                case 1:
                this.$emit('move', { transitionType: 'next', to: '/' });
                break;

                case 2:
                this.$emit('move', { transitionType: 'next', to: '/hej' });
                break;

                case 3:
                this.$emit('move', { transitionType: 'next', to: '/test' });
                break;

                case 4:
                this.$emit('move', { transitionType: 'next', to: '/information' });
                break;

                case 5:
                this.$emit('move', { transitionType: 'next', to: '/inspiration' });
                break;

                case 6:
                this.$emit('move', { transitionType: 'next', to: '/kontakt' });
                break;
            }
        }
    }
}
</script>

<style lang="scss">

.pager {
    display: flex;
    position: relative;
    height: 12vh;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            width: 6px;
            height: 6px;
            background: rgba($color: #FFF, $alpha: .3);
            border-radius: 999rem;
            margin: .5rem;
            transition: background .2s ease;

            &.active {
                background: rgba($color: #FFF, $alpha: 1);
            }
        }
    }
}
</style>
