<template>
    <article class="ingress-article">
       <h2>{{data.title}}</h2>
       <p v-html="data.shortDesc"></p>
       <a href="#" class="btn sml" @click.prevent="$router.push(`/information/program/${data.code}`)">Läs mer om {{ data.code }}</a>
    </article>
</template>

<script>
export default {
    name: 'programarticle',
    props: ['data', 'knowmore'],
    data(){
        return {
            content: '',
            readMore: false
        }
    }
}
</script>

<style lang="scss">
@import '../../scss/variables';

.ingress-article {
    border-radius: $radius;
    background: white;
    margin-bottom: 1rem;
    padding-bottom: .01rem;

    h2 {
        margin: .5rem 0;
    }

    header {

        figure {
            
            img {
                border-top-left-radius: $radius;
                border-top-right-radius: $radius;
                width: 100%;
            }
        }

        h1 {
            margin: 1rem 0 0 0;
            padding: 0 1rem;
        }

        .ingress {
            margin: 1rem;
            font-weight: 400;
            font-size: 1rem;
        }
    } 

    .content {
        padding: 1rem !important;
        
        .active {
            background: red;
        }

        ul {
            list-style: none !important;

            li {
                padding: 0 .75rem;
                margin: 0 0 1rem 0;
                border-left: .25rem solid rgba($color: #000000, $alpha: .2);

                p {
                    margin: 0;
                    padding: 0;
                    font-style: italic;
                    font-size: .8rem;
                }
            }
        }
    }

    .readmore {
        @extend %center;
        height: 3rem;
        margin: 1rem;
        border: 1px solid rgba($color: $darkgrey, $alpha: 1);
        border-radius: $radius;
        color: $darkgrey;
        text-decoration: none;
        font-weight: 700;
    }
}

</style>
